@font-face {
  font-family: 'Highway Gothic';
  src: local('HighwayGothic'), url(./HWYGOTH.TTF) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Raleway'), url(./Raleway-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Raleway'), url(./Raleway-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Brother';
  src: local('Brother'), url(./Brother1816-Medium.ttf) format('truetype');
}
