.datepicker-dateList {
  display: flex;
  margin: 2px 0 2px 0;
}

.datepicker-date-day-Item {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 12px 0 12px;
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 50%;
}

.datepicker-button-previous-wrapper {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 0 0 12px;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 50%;
  /* color: #e6545b; */
}

.datepicker-strip {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: max-content;
  max-width: 100%;
  overflow: hidden;
  line-height: 1.5;
}

.date-day-Item-selected {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.datepicker-date-day-Item:hover {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  opacity: 0.6;
}

.date-day-item-disabled {
  color: rgb(176, 176, 176);
  pointer-events: none;
}

.datepicker-datelist-scrollable {
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none;
  /* margin: 2px 0 2px 0; */
}

.datepicker-datelist-scrollable::-webkit-scrollbar {
  -webkit-appearance: none;
  display: none;
}

.datepicker {
  display: flex;
  align-items: center;
  position: relative;
}

.datepicker-day-label {
  font-size: 12px;
  margin: 7px 0 0 0;
  text-align: center;
}

.scroll-head {
  /* border: 1px solid #e9e9e9; Shivam*/
  border-radius: 2px;
  margin: 8px 0px 8px 0px;
  text-align: center;
  height: 18px;
  font-size: 12px;
  font-weight: 600;
  /* background: #e9e9e9; Shivam*/
}
.blank-space-div {
  border: 1px solid transparent;
  border-radius: 2px;
  margin: 8px 0px 8px 0px;
  text-align: center;
  height: 18px;
  font-size: 12px;
}

.datepicker-button-previous,
.datepicker-button-next {
  border: none;
  text-decoration: none;
  background: transparent;
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: #418fde;
  font-size: 18px;
  font-weight: bold;
  flex-shrink: 0;
}

.button-previous {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 40px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  /* color: #e6545b; */
}

.datepicker-button-previous {
  transform: rotate(180deg);
}

.datepicker-month-label {
  font-size: 22px;
  line-height: 25px;
  font-weight: 500;
  color: #418fde;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
}

.datepicker-date-label {
  /* font-size: 12px; */
  margin-top: -3px;
}
.wrapper {
  position: relative;
  /* border: 1px solid #000; */
}
@-webkit-keyframes ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0.9;
  }
  100% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0.9;
  }
  100% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
.ripple:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: currentColor;
  visibility: hidden;
  z-index: 2;
}
.ripple:not(:active):before {
  -webkit-animation: ripple 0.4s cubic-bezier(0, 0, 0.2, 1);
  animation: ripple 0.4s cubic-bezier(0, 0, 0.2, 1);
  transition: visibility 0.9s step-end;
}
.ripple:active:before {
  visibility: visible;
}
